<template>
  <div class="d-flex flex-row">
    <ProfileMenu ref="profileMenu" />
    <!--begin::Content-->
    <b-row class="flex-row-fluid ml-lg-8">
      <b-card>
        <b-form @submit.prevent="onSubmit" v-if="!isLoading">
          <b-row>
            <b-col md="4">
              <div class="p-2">
                <b-form-group
                  id="input-group-1"
                  label="First name:"
                  label-for="input-1"
                >
                  <b-row>
                    <b-form-input
                      id="input-1"
                      v-model="form.firstName"
                      type="text"
                      required
                      placeholder="Enter first name"
                    ></b-form-input>
                  </b-row>
                </b-form-group>
              </div>
            </b-col>
            <b-col md="4">
              <div class="p-2">
                <b-form-group
                  id="input-group-1"
                  label="Last name:"
                  label-for="input-1"
                >
                  <b-row>
                    <b-form-input
                      id="input-1"
                      v-model="form.lastName"
                      type="text"
                      required
                      placeholder="Enter last name"
                    ></b-form-input>
                  </b-row>
                </b-form-group>
              </div>
            </b-col>
            <b-col md="4">
              <div class="p-2">
                <b-form-group
                  id="input-group-1"
                  label="Email address:"
                  label-for="input-1"
                >
                  <b-row>
                    <b-form-input
                      id="input-1"
                      disabled
                      v-model="form.email"
                      type="email"
                      required
                      placeholder="Enter email"
                    ></b-form-input>
                  </b-row>
                </b-form-group>
              </div>
            </b-col>
            <b-col md="4">
              <div class="p-2">
                <b-form-group
                  id="input-group-1"
                  label="Phone number:"
                  label-for="input-1"
                >
                  <b-row>
                    <b-form-input
                      id="input-1"
                      v-model="form.phoneNumber"
                      type="tel"
                      required
                      placeholder="Enter phone number"
                    ></b-form-input>
                  </b-row>
                </b-form-group>
              </div>
            </b-col>
            <b-col md="8" v-if="profilePicture">
              <div class="p-2">
                <b-form-group
                  id="input-group-1"
                  label="Profile picture:"
                  label-for="input-1"
                >
                  <div class="card" style="width: fit-content">
                    <img
                      :src="profilePictureURL"
                      style="
                        max-height: auto;
                        background-size: cover;
                        border-radius: 6px;
                        width: 6rem !important;
                        height: 6rem !important;
                        object-fit: cover;
                      "
                      alt="Profile Picture"
                    />
                  </div>
                  <p class="text-info small mt-1" v-if="isImageUpdate">
                    Press update to update your profile picture
                  </p>
                </b-form-group>
              </div>
            </b-col>
          </b-row>

          <div class="float-right ml-3">
            <input
              type="file"
              ref="file"
              style="display: none"
              @change="onImageChanged"
              accept="image/jpeg, image/png"
              tabindex="-1"
            />
            <b-button
              @click="$refs.file.click()"
              variant="warning"
              ref="uploadDpButton"
              class="font-weight-bold"
              ><i class="la la-upload"></i>Upload Profile Picture</b-button
            >
          </div>

          <div class="float-right">
            <b-button
              type="submit"
              variant="success"
              ref="updateUserButton"
              class="font-weight-bold"
              ><i class="la la-check"></i>Update</b-button
            >
          </div>
        </b-form>
      </b-card>
    </b-row>
    <!--end::Content-->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {
  UPDATE_USER,
  UPDATE_PROFILE_PICTURE,
} from "@/core/services/store/auth.module";
import ProfileMenu from "@/view/layout/aside/ProfileMenu";
export default {
  components: { ProfileMenu },
  data() {
    return {
      isLoading: false,
      profilePictureURL: null,
      profilePicture: null,
      isImageUpdate: false,
      form: {
        email: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
      },
    };
  },
  created() {
    // if (this.currentUser) {
    this.form = {
      email: this.currentUser.email,
      firstName: this.currentUser.name.firstName,
      lastName: this.currentUser.name.lastName,
      phoneNumber:
        this.currentUser.contactNumber || this.currentUser.phoneNumber,
    };
    // }
  },
  // mounted() {
  //   // if (this.currentUser) {
  //   this.form = {
  //     email: this.currentUser.email,
  //     firstName: this.currentUser.name.firstName,
  //     lastName: this.currentUser.name.lastName,
  //     phoneNumber: this.currentUser.phoneNumber,
  //   };
  //   // }
  // },
  watch: {
    currentUser() {
      this.form = {
        email: this.currentUser.email,
        firstName: this.currentUser.name.firstName,
        lastName: this.currentUser.name.lastName,
        phoneNumber:
          this.currentUser.contactNumber || this.currentUser.phoneNumber,
      };
    },
  },
  methods: {
    onImageChanged(event) {
      this.profilePicture = event.target.files[0];
      this.isImageUpdate = true;
      this.profilePictureURL = URL.createObjectURL(event.target.files[0]);
    },
    onSubmit() {
      const updateUserButton = this.$refs["updateUserButton"];
      updateUserButton.classList.add(
        "spinner",
        "spinner-light",
        "spinner-right"
      );

      if (typeof this.currentUser === "undefined") return;

      let update = {
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        phoneNumber: this.form.phoneNumber,
        userId: this.currentUser._id,
        requestBy: this.currentUser._id,
      };

      var data = new FormData();

      if (this.isImageUpdate) {
        data.append("userId", this.currentUser._id);
        data.append("dp", this.profilePicture, this.profilePicture.data);
      }

      Promise.all(
        this.isImageUpdate
          ? [
              this.$store.dispatch(UPDATE_PROFILE_PICTURE, data, {
                headers: {
                  "content-type": "multipart/form-data",
                },
              }),
              this.$store.dispatch(UPDATE_USER, update),
            ]
          : [this.$store.dispatch(UPDATE_USER, update)]
      )
        .then(() => {
          console.log("res");
          // trigger dp update
          // if (this.isImageUpdate) {
          //   this.$root.$refs.ProfileMenu.increment();
          // }
          this.$bvToast.toast(`Account details successfully updated`, {
            title: `Submitted`,
            variant: "success",
            solid: true,
            appendToast: true,
          });
        })
        .catch((err) => {
          this.$bvToast.toast(
            typeof err !== "undefined"
              ? err.message
              : "Failed to account details user account.",
            {
              title: `Update failed`,
              variant: "danger",
              solid: true,
              appendToast: true,
            }
          );
        })
        .finally(() => {
          this.isImageUpdate = false;
          data = null;
          updateUserButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
};
</script>
